<template> 
    <b-modal ref="modal-add" size="md" v-model="computedShow" no-close-on-backdrop :title="trans('hr-reject-by-client',272)">
        
        <ValidationObserver ref="form" >
            <b-form @submit.prevent="onSubmit">

                <app-row-left-label :label="trans('notes-client',209)">
                    <app-memo v-model="description" :maxLength="500" :lettersCounter="true" :validatorName="'Toelichting'" validatorRules="required"/>
                </app-row-left-label>
                
            </b-form>
        </ValidationObserver> 
        
        <template #modal-footer>
            <app-button type="cancel" @click="closeModal">{{trans('cancel',175)}}</app-button>
            <app-button type="primary" @click="confirm" :loading="loading">{{trans('confirm-data',175)}}</app-button>
        </template>

      </b-modal>
    
</template>

<script>
import axios from "@axios";
import AppAlerts from '@core/scripts/AppAlerts';
import {  ValidationObserver } from "vee-validate";

export default {
    components: {
        ValidationObserver
    },

    props: ["show", "result", "args", "return"],

    mounted(){
    },

    computed: {
        computedShow: {
            get() { return this.show },
            set(value) { this.$emit('update:show', value) }, 
        },

        cReturn: {
            get() { return this.return },
            set(value) { this.$emit('update:return', value) }, 
        },

        cArgs: {
            get() { return this.args },
            set(value) { this.$emit('update:args', value) }, 
        },

    },

    created(){},

    data() {
        return {
            loading: false,
            description: ""
        };
    },

    methods: {
        
        confirm(){

            this.$refs.form.validate().then((result) => {
                if (!result) {
                    return false;
                }
                else{
                    this.loading = true;

                    axios
                        .post("planning/hours/rejectByClient", {
                            id_hour_data_main: this.cArgs.id_hour_data_main,
                            id_shift_data_main: this.cArgs.id_shift_data_main,
                            return_type: this.cArgs.return_type,
                            description: this.description
                        })
                        .then((res) => {
                            this.$emit("update:show", false);
                            this.$emit("update:result", 1);
                            
                            if(this.args.return_type == "hours_list"){
                                //this.cReturn = res.data.items;
                            }else if(this.args.return_type == "shift_profile"){
                                this.cReturn.shift_data = res.data;
                                this.cReturn.history.items.refresh ++;
                            }else if(this.args.return_type == "hour_profile"){
                                this.cReturn.hour_data = res.data;
                                this.cReturn.history.items.refresh ++;
                            }
                            
                            this.loading = false;
                        })
                        .catch((error) => {
                            console.error(`error during request: ${error}`);
                        });
                }
            });

        },

        

        closeModal() {
            this.$emit("update:show", false);
        },

    },
};
</script>

<style scoped>



</style>

