<template>
    <b-modal ref="modal-add" size="md" v-model="computedShow" @close="deleteFile(true)" no-close-on-backdrop :title="trans('sh-add-declaration', 215)">
        <ValidationObserver ref="form" >
            <b-form @submit.prevent>
                <app-row-left-label :label="trans('sh-amount', 215)">
                    <app-input v-model="amount" validatorRules="required" :validatorName="trans('sh-amount', 215)" type="decimal_07_places_02" />
                </app-row-left-label>

                <b-row class="mt-3" v-if="document">
                    <b-col md="7" xl="7" style="margin-top: 3px">
                        <span class="app-documents-item"><div class="w-100 text-truncate">{{ document.file_name }}</div></span>
                    </b-col>
                    <b-col md="2" xl="2" class="text-right">
                        <span class="app-documents-item font-weight-bolder"> {{ document.file_size_mb }} MB</span>
                    </b-col>
                    <b-col md="2" xl="2">
                            <b-link href="#" @click="deleteFile()">
                                <span class="app-documents-item" v-if="!f_cancel">{{trans('remove',175)}}</span>
                                <b-spinner class="ml-2" small v-if="f_cancel" />
                            </b-link>
                    </b-col>
                </b-row>

                <div class="image-upload-wrap mt-3" v-if="document == null">
                    <input class="file-upload-input" type="file" v-on:change="addFile($event)" accept="image/png, image/jpeg, image/jpg, application/pdf"/>
                    <div class="drag-text">
                        <img src="@/assets/images/icons/download-cloud.svg" height="40px" v-if="!f_uploading" />
                        <b-spinner v-if="f_uploading" class="spinner-margin" />
                    <div>
                        <small class="text-muted">{{trans('drag-and-drop-files-jpg-png-pdf',180)}}</small>
                    </div>
                    </div>
                </div>

            </b-form>
        </ValidationObserver>
        <template #modal-footer>
            <app-button type="cancel" @click="deleteFile(true)">{{trans('cancel',175)}}</app-button>
            <app-button type="primary" :disabled="document == null" @click="add" :loading="loading">
                {{trans('add',175)}}
            </app-button>
        </template>
    </b-modal>
</template>

<script>
import axios from "@axios";
import { ValidationObserver } from "vee-validate";

export default {
    components: {
        ValidationObserver,
    },

    props: ["show", "args", "return", "result"],

    computed: {
        computedShow: {
        get() { return this.show },
        set(value) { this.$emit('update:show', value) }, 
        },

        cArgs: {
            get() { return this.args },
            set(value) { this.$emit('update:args', value) }
        },

        cReturn: {
            get() { return this.return },
            set(value) { this.$emit('update:return', value) }
        },
    },

    watch: {

    },

    data() {
        return {
            loading: false,
            amount: null,
            f_uploading: false,
            document: null,
            f_cancel: false,
            form_data: new FormData(),
        };
    },

    methods: {
        add() {            
          
            this.$refs.form.validate().then((result) => {
                if (!result) { 
                    return false;
                }
                
                this.cReturn = {
                    amount: this.amount.replace(',', '.'),
                    document: this.document,
                    id_placement_const_component: this.args.id_placement_const_component,
                    name: this.args.component_type_name,
                    id_document_const_type: this.args.id_document_const_type
                };

                this.$emit("update:show", false);
                this.$emit("update:result", 1);

            });
        },

        setFormatDate(dateValue) {
           return moment(dateValue).format('DD-MM-YYYY');
        },

        addFile(e){
            var file = e.target.files[0];

            if(!file.type.match('image/jp.*') && !file.type.match('image/png.*') && !file.type.match('application/pdf.*')) {
                    alert(this.trans('only-jpg-png',180));
                    file = "";
                    return;
            }

            this.form_data.append("file", file);
            this.form_data.append("file_name", file.name);

            this.form_data.append("file_size", file.size);
            this.form_data.append("file_size_mb", (file.size / 1024 / 1024).toFixed(2));

            this.sendFile();
        },

        sendFile(){

            const config = {
                headers: { "content-type": "multipart/form-data" },
            };


            this.f_uploading = true;
            axios
                .post("core/addTempFile", this.form_data, config)                
                .then((response) => {
                    this.form_data.append("temp_path", response.data);

                    var object = {};
                    this.form_data.forEach((value, key) => object[key] = value);                    
                    this.document = object;
                    
                    this.form_data = new FormData();
                    this.f_uploading = false;
                })
                .catch(function(error) {
                    this.f_uploading = false;

                    console.log(error);
                });
        },

        deleteFile(close = false){
            
            if(this.document != null){
                this.f_cancel = true;

            axios
                .post("core/removeTempFile",  {
                    documents: JSON.stringify([this.document]),
                        })
                .then((response) => {
                    this.f_cancel = 0;
                    this.document = null;
                    if(close) this.closeModal();
                })
                .catch(function(error) {
                    this.f_cancel = 0;
                    console.log(error);
                });
                            
            } else {
                this.closeModal();
            }
        },

        closeModal() {
            this.$emit("update:show", false);
            this.$emit("update:result", -1);
        },




    },
};
</script>

<style scoped>

.image-upload-wrap {
    border: 1px dashed #BBE7FA;
    border-radius: 5px;
    position: relative;
    transition: 1s;
}

.image-dropping,
.image-upload-wrap:hover {
    background-color: #f8fcfe;
}

.drag-text {
    text-align: center;
    padding: 20px 10px;
}

.drag-text p {
    color: #000000;
    font-size: 12px;
}

.file-upload-input {
    position: absolute;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    outline: none;
    opacity: 0;
    cursor: pointer;
}
.spinner-margin{
    margin-top: 4px; 
    margin-bottom:6px
}

.cancel-text{
    font-size: 13px;
}

</style>
