<template> 
    <b-modal ref="modal-add" size="md" v-model="computedShow" no-close-on-backdrop :title="trans('h-confirm-declaration-services',272)">
        
        <ValidationObserver ref="form" >
            <b-form @submit.prevent="onSubmit">

                <app-row-left-label :label="trans('sh-begin-and-end-time',215)" :slots="[6,6]">
                    <template v-slot:col-1>
                        <app-time v-model="cArgs.time_from" validatorName="Begin tijd" validatorRules="required"  v-on:input="calculateHours"/>
                    </template>
                    <template v-slot:col-2>
                        <app-time v-model="cArgs.time_to" validatorName="Eind tijd" validatorRules="required"   v-on:input="calculateHours"/>
                    </template>
                </app-row-left-label>
                
                <!--If -->
                <app-row-left-label :label="trans('sh-pause',215)" v-if="cArgs.hours_json.pause_type == 1">
                    <app-time v-model="cArgs.pause" validatorName="Pauze" validatorRules="required" valueType="mm" placeholder="MM" format="mm" :minuteStep="5" v-on:input="calculateHours"/>
                </app-row-left-label>

                <app-row-left-label :label="trans('sleeping-service', 182)" v-if="cArgs.hours_json.sleeping_shift == 1">
                    <div v-for="item, index in sleeping_shifts" :key="index" class="d-flex mb-2">
                            <app-time v-model="item.time_from" class="mr-2" v-on:input="calculateSleepingShift"/>

                            <app-time v-model="item.time_to" v-on:input="calculateSleepingShift"/>

                            <app-button type="secondary-grey" class="ml-1 app-local-button p-2" :class="{ 'app-local-button-disabled':index == 0 && sleeping_shifts.length == 1 }" @click="removeSleepShift(index)" :disabled="index == 0 && sleeping_shifts.length == 1">
                                <b-icon icon="dash" class="list-button-icon"></b-icon>
                            </app-button>
                    </div>
                    <div class="d-flex justify-content-end mt-1">
                        <app-button type="cancel" @click="addSleepShift" class="p-0 app-local-text"><b-icon icon="plus-circle" class="mr-1" /> <span> {{trans('add',175)}} </span></app-button>
                    </div>
                </app-row-left-label>

                
                <b-overlay :show="loading" :opacity="0.6" variant="transparent"> 
                    <b-card no-body class="pt-2 pb-2 pl-3 pr-3 mt-2" v-if="data.hours.length > 0">
                        <b-row class="app-card-header">
                            <b-col start>
                                <h4 class="card-title">
                                    {{trans('sh-worked-hours',215)}}    
                                </h4>
                            </b-col>
                        </b-row>
                        <b-row class="ml-1 mr-1 pb-2 app-local-row-header-font">
                            <b-col class="pl-1">
                                {{trans('sh-description',215)}}
                            </b-col>
                            <b-col end cols="auto" class="app-width-min-100">
                                {{trans('sh-compensation',215)}}
                            </b-col>
                            <b-col end cols="auto" class="app-width-min-100">
                                {{trans('sh-duration',215)}}  
                            </b-col>
                        </b-row>

                        <b-list-group flush class="app-local-bottom-line">
                            
                            <b-list-group-item class="p-2 ml-2 mr-2" v-for="item in cData.hours" :key="item.index">
                                <b-row align-v="center" class="pl-2">
                                    <b-col class="p-0">
                                        <span class="pl-1 app-local-row-font" v-if="item.type == 1"> {{ item.time_from }} - {{ item.time_to}} </span>
                                        <span class="pl-1 app-local-row-font" v-if="item.type == 2"> {{trans('sh-pause', 215)}} </span>
                                        <span class="pl-1 app-local-row-font" v-if="item.type == 3">Slaapdienst {{ item.time_from }} - {{ item.time_to}} </span>
                                    </b-col>
                                    <b-col end cols="auto" class="app-width-min-100">
                                        <span class="pl-1 app-local-row-font"> {{ parseInt(item.percent)+100 }}% </span>
                                    </b-col>
                                    <b-col end cols="auto" class="app-width-min-100">
                                        <span class="pl-1 app-local-row-font"> {{ item.hours.toString().replace(".", ",") }} {{trans('sh-hour', 215).toLowerCase()}} </span>
                                    </b-col>
                                </b-row>
                            </b-list-group-item>

                            <b-list-group-item class="p-2 ml-2 mr-2" v-if="cData.hours.length > 0">
                                <b-row align-v="center" class="pl-2">
                                    <b-col class="p-0">
                                        <h4 class="pl-1 card-title mb-2 mt-2"> {{trans('sh-total-work-hours', 215)}} </h4>
                                    </b-col>
                                    <b-col end cols="auto" class="app-width-min-100">
                                    </b-col>
                                    <b-col end cols="auto" class="app-width-min-100">
                                        <h4 class="pl-1 card-title mb-2 mt-2"> {{cTotalHours}} {{trans('sh-hour', 215).toLowerCase()}} </h4>
                                    </b-col>
                                </b-row>
                            </b-list-group-item>

                        </b-list-group>

                    </b-card>
                    <template #overlay>
                        <div></div>
                    </template>

                </b-overlay>

                <b-overlay :show="loading" :opacity="0.6" variant="transparent"> 
                    <b-card no-body class="pt-2 pb-2 pl-3 pr-3 mt-2">
                        <b-row class="app-card-header">
                            <b-col start>
                                <h4 class="card-title">
                                {{trans('declaration',3)}} 
                                </h4>
                            </b-col>
                        </b-row>

                        <b-row class="ml-1 mr-1 pb-2 app-local-row-header-font">
                            <b-col class="pl-1">
                                {{trans('sh-description',215)}}
                            </b-col>
                            <b-col end cols="auto" class="app-width-min-100">
                                {{trans('sh-number-of-km',215)}} 
                            </b-col>
                            <b-col end cols="auto" class="app-width-min-100">
                                {{trans('sh-amount',215)}}
                            </b-col>
                        </b-row>

                        <b-list-group flush class="app-local-bottom-line">
                            
                            <b-list-group-item class="p-2 ml-2 mr-2" v-for="(item, index) in cData.declarations" :key="item.index">
                                <b-row align-v="center" class="pl-2">
                                    <b-col class="p-0">
                                        <span class="pl-1 app-local-row-font"> {{ item.name }} </span>
                                    </b-col>
                                    <b-col end cols="2" class="app-width-min-100">
                                        <app-input v-model="item.distance" v-if="[3, 4].includes(item.id_placement_const_component) " type="integer_02"/>
                                    </b-col>
                                    <b-col end cols="auto" class="app-width-min-100">
                                        <span class="pl-1 app-local-row-font" v-if="[3, 4, 9].includes(item.id_placement_const_component)"> €{{ ((item.distance != null ? item.distance : 1) * parseFloat(item.person_value)).toFixed(2).replace(".", ",") }} </span>
                                        <span class="pl-1 app-local-row-font" v-else> €{{  parseFloat(item.amount).toFixed(2).replace(".", ",") }} </span>
                                    </b-col>
                                    <app-button type="cancel" @click="removeDeclaration(index)" class="p-0 app-local-button-remove"><b-icon icon="x" class="custom-x" lg font-scale="1.5" style="color:#066791"></b-icon></app-button>
                                </b-row>
                                <div class="app-local-document-text" v-if="[6].includes(item.id_placement_const_component)">{{ item.document.file_name }} </div>
                            </b-list-group-item>

                            <b-list-group-item class="p-2 ml-2 mr-2">
                                <b-row align-v="center" class="pl-2">
                                    <b-col class="p-0">
                                        <app-button type="cancel" @click="addDeclaration" class="p-0 app-local-text"><b-icon icon="plus-circle" class="mr-1" /> <span> {{trans('add-declaration',175)}}</span></app-button>
                                    </b-col>
                                </b-row>
                            </b-list-group-item>

                            <b-list-group-item class="p-2 ml-2 mr-2" v-if="cData.hours.length > 0">
                                <b-row align-v="center" class="pl-2">
                                    <b-col class="p-0">
                                        <h4 class="pl-1 card-title mb-2 mt-2"> {{trans('sh-total-declaration',215)}} </h4>
                                    </b-col>
                                    <b-col end cols="auto" class="app-width-min-100">
                                    </b-col>
                                    <b-col end cols="auto" class="app-width-min-100">
                                        <h4 class="pl-1 card-title mb-2 mt-2"> €{{cTotalDeclaratie}}  </h4>
                                    </b-col>
                                </b-row>
                            </b-list-group-item>

                        </b-list-group>

                    </b-card>
                    <template #overlay>
                        <div></div>
                    </template>

                </b-overlay>

                <b-tabs ref="tabs" class="mb-3 mt-3" justified >
                    <b-tab :title="trans('notes-flexworker',209)">
                        <app-memo v-model="cArgs.person_description" :maxLength="500" :lettersCounter="true" />
                    </b-tab>  
                    <b-tab :title="trans('notes-client',209)">
                            <app-memo v-model="cArgs.client_description" :maxLength="500" :lettersCounter="true" />
                    </b-tab>
                </b-tabs>
            </b-form>
        </ValidationObserver> 
        
        <template #modal-footer>
            <app-button type="cancel" @click="closeModal">{{trans('cancel',175)}}</app-button>
            <app-button type="primary" @click="confirm" :loading="loading" :disabled="data.id_placement_data_main == null">{{trans('confirm-data',175)}}</app-button>
        </template>

        <hours-profile-declaration-parking-cost v-if="mAdd.show" :show.sync="mAdd.show" :args.sync="mAdd.args" :result.sync="mAdd.result" :return.sync="mAdd.return" />
        <hours-profile-declaration-add-step-1 v-if="mAddStep1.show" :show.sync="mAddStep1.show" :args.sync="mAddStep1.args" :result.sync="mAddStep1.result" :return.sync="mAddStep1.return"/>
      </b-modal>
    
</template>

<script>
import axios from "@axios";
import AppAlerts from '@core/scripts/AppAlerts';
import {  ValidationObserver } from "vee-validate";
import HoursProfileDeclarationAddStep1 from './HoursProfileDeclarationAddStep1.vue';
import HoursProfileDeclarationParkingCost from './HoursProfileDeclarationParkingCost.vue';

export default {
    components: {
        ValidationObserver,
        HoursProfileDeclarationAddStep1,
        HoursProfileDeclarationParkingCost
    },

    props: ["show", "result", "args", "return"],

    mounted(){
        this.data = this.cArgs.hours_json;

        this.data.hours.forEach(item => {
            if(item.type == 3){
                this.sleeping_shifts.push(item);
            }
        });

        if(this.sleeping_shifts.length == 0){
            this.sleeping_shifts = [{time_from: null, time_to: null}];
        }

    },

    watch:{
        "mAdd.result": {
            handler(value){
                if(this.mAdd.result == 1 && this.mAdd.return != null) {
                    this.cData.declarations.push(this.mAdd.return);
                }
            }
        },

        "mAddStep1.return": {
            handler(value){
                if(this.mAddStep1.return != null) {
                    this.addParkingDeclaration(this.mAddStep1.return)
                }
            }
        }
    },

    computed: {
        computedShow: {
            get() { return this.show },
            set(value) { this.$emit('update:show', value) }, 
        },

        cData: {
            get() { return this.data },
            set(value) { this.$emit('update:data', value) }, 
        },

        cReturn: {
            get() { return this.return },
            set(value) { this.$emit('update:return', value) }, 
        },

        cArgs: {
            get() { return this.args },
            set(value) { this.$emit('update:args', value) }, 
        },

        cTotalHours : {
            get() { 
                    if(this.data.hours.length > 0){
                        
                        let hours = 0;
                        this.data.hours.forEach(item => {
                            if(item.type == 1){
                                hours = hours + item.hours;
                            }
                        });

                        return hours.toString().replace(".", ",");
                    }else{
                        return 0;
                    }
                },
        },

        cTotalDeclaratie : {
            get() { 
                    if(this.data.declarations.length > 0){

                        let value = 0;
                        this.data.declarations.forEach(item => {

                            if([3, 4].includes(item.id_placement_const_component)){
                                value = value + (item.distance * (parseFloat(item.person_value)).toFixed(2));
                            }else if(item.id_placement_const_component == 9){
                                value = value + parseFloat(item.person_value);
                            }else{
                                value = value + parseFloat(item.amount);
                            }

                        });

                        this.cData.total_declarations_value = value.toFixed(2);
                        return value.toFixed(2).replace(".", ",");
                    }else{
                        return 0;
                    }
                },
        },
    },

    created(){},

    data() {
        return {
            loading: false,
            data: {
                hours: [],
                declarations: [],
                id_placement_data_main: null
            },
            alert_class: new AppAlerts(),
            mAdd: { show: false, args:{id_placement_const_component: null, component_type_name: null, id_document_const_type: null}, result: 0, return: null},
            mAddStep1: {show: false, args:{}, result: 0, return: null},
            sleeping_shifts: [],
            sleeping_time: []
        };
    },

    methods: {
        
        calculateHours() {
            this.$refs.form.validate().then((result) => {
                if (!result) {
                    return false;
                }
            else{
                    this.loading = true;
                axios
                    .post("planning/hours/recalculate", {
                        id_hour_data_main: this.cArgs.id_hour_data_main,
                        hour_from: this.cArgs.time_from,
                        hour_to: this.cArgs.time_to,
                        pause: this.cArgs.pause,
                        sleeping_time: JSON.stringify(this.sleeping_time),
                    })
                    .then((res) => {
                        this.data.hours = res.data.hours;
                        this.data.total_hours_value = res.data.total_hours_value;
                        this.data.pause = res.data.pause;

                    })
                    .catch((error) => {
                        console.error(`error during request: ${error}`);
                    })
                    .finally(() => {
                        this.loading = false;
                    });;
                }
            })
                
        },

        confirm(){

            this.$refs.form.validate().then((result) => {
                if (!result) {
                    return false;
                }
                else{
                    this.loading = true;

                    axios
                        .post("planning/hours/confirm", {
                            id_shift_data_main: this.cArgs.id_shift_data_main,
                            id_hour_data_main: this.cArgs.id_hour_data_main,
                            hour_from: this.cArgs.time_from,
                            hour_to: this.cArgs.time_to,
                            pause: this.cArgs.pause,
                            person_description: this.cArgs.person_description,
                            client_description: this.cArgs.client_description,
                            hours_data: JSON.stringify(this.data),
                            sleeping_time: JSON.stringify(this.sleeping_time),
                            filters: JSON.stringify(this.args.filters),
                            return_type: this.cArgs.return_type
                        })
                        .then((res) => {
                            this.$emit("update:show", false);
                            this.$emit("update:result", 1);
                            
                            if(this.args.return_type == "hours_list"){
                                this.cReturn = res.data.items;
                            }else if(this.args.return_type == "shift_profile"){
                                this.cReturn.shift_data = res.data;
                                this.cReturn.history.items.refresh ++;
                            }else if(this.args.return_type == "hour_profile"){
                                this.cReturn.hour_data = res.data;
                                this.cReturn.history.items.refresh ++;
                            }
                            
                            this.loading = false;
                        })
                        .catch((error) => {
                            console.error(`error during request: ${error}`);
                        });
                }
            });

        },

        addDeclaration(){
            this.mAddStep1.return = null;
            this.mAddStep1.result = 0;
            this.mAddStep1.show = true;
        },

        addParkingDeclaration(item){
            this.mAdd.result = 0;
            this.mAdd.args.id_placement_const_component = item.id;
            this.mAdd.args.component_type_name = item.component_type_name;
            this.mAdd.args.id_document_const_type = item.id_document_const_type;
            this.mAdd.show = true;
        },

        removeDeclaration(index){
            this.cData.declarations.splice(index, 1);
        },

        addSleepShift(type){
            this.sleeping_shifts.push({time_from:null, time_to:null});
        },

        removeSleepShift(index){
            this.sleeping_shifts.splice(index, 1);
            this.calculateSleepingShift();
        },

        calculateSleepingShift(){

            this.sleeping_time = [];

            this.sleeping_shifts.forEach(item => {

                if(item.time_from != null && item.time_to != null){
                    this.sleeping_time.push(item);
                }

            });

            if(this.sleeping_time.length > 0){
                this.calculateHours();
            } 

        },

        closeModal() {
            this.$emit("update:show", false);
        },

    },
};
</script>

<style scoped>

.app-local-row-font {
    color: #174066;
    font-weight: 300;
    font-size: 14px;
}

.app-local-row-header-font {
    font-weight: 600;
    color: #1f627f;
    font-size: 14px;
    text-transform: none;
    letter-spacing: 1px;
    border-bottom: 2px solid rgba(0, 0, 0, 0.05);
}

.app-local-bottom-line {
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.app-local-text{
    color: #7f7f7f !important;
}

.app-local-text:hover{
    color: black !important;
    opacity: 0.8;
}

.app-local-text:focus{
    color: black !important;
    opacity: 0.8;
}

.app-local-document-text{
    color: #7f7f7f !important;
    font-size: 11px;
}

.app-local-button-remove{
    position:absolute;
    right: 5px;
}

.app-local-button{
    opacity: 1 !important;
    border: solid 1px #D0D5DD !important;
    border-radius: 0.25rem !important;
    line-height: 1rem !important;
    font-size:  1rem !important;
    color: #066791 !important;
    max-height: 31.3px !important;
    min-height: 31.3px !important;
}
.app-local-button:hover{
    border-color: #066791 !important;
    box-shadow: 0px 0px 5px -2px rgba(13, 126, 179, 1);
}

.app-local-button:focus{
    border-color: #066791 !important;
    box-shadow: 0px 0px 5px -2px rgba(13, 126, 179, 1);
}

.app-local-button-disabled:hover, .app-local-button-disabled:focus{
    border: solid 1px #D0D5DD !important;
    box-shadow: none !important;
}


</style>

